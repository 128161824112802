<template>
  <div>
    <div class="mb-5 flex justify-center" style="border-bottom: 1px solid gainsboro;">
      <router-link to="/">
        <img src="/melior_dk.svg" alt="" class="mb-4 h-8">
      </router-link>
    </div>
    <div class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-5 col-md-offset-3">
            <div class="row mt-topp">
              <div class="col-lg-12">
                <div v-if="!emailSent">
                  <div class="mb-5">
                    <h4 class="text-dark text-4xl font-weight-bold">
                      Forgot password
                    </h4>
                  </div>

                  <div>
                    <form @submit.prevent="submitForm" role="form">
                      <div class="form-group">
                        <label class="text-dark" to="email">Email address</label>
                        <input v-model="email" required type="email" name="email" tabindex="1" id="email"
                          class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.email" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group text-center mt-5">
                        <button :disabled="loading" type="submit"
                          class="bg-primary text-white py-3 px-4 mr-3 text-sm hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full">
                          Reset
                          <BtnLoading v-if="loading" class="d-inline-block" height="18" />
                        </button>
                      </div>
                      <p>
                        Already have an account?
                        <router-link class="text-primary text-underline" to="/login">Login</router-link>
                      </p>
                    </form>
                  </div>
                </div>

                <div v-else>
                  <div class="mb-5">
                    <div class="row align-items-center">
                      <div class="col-8">
                        <h4 class="text-dark font-weight-bold">
                          Forgot password
                        </h4>
                      </div>
                      <div class="col-4 text-right">
                        <a href="#" @click="emailSent = false">← Back</a>
                      </div>
                    </div>
                  </div>

                  <div>
                    <form @submit.prevent="submitFormReset" role="form">
                      <div class="form-group">
                        <label class="text-dark" to="email">Email address</label>
                        <input v-model="email" disabled type="email" name="email" tabindex="1" id="email"
                          class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.email" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="text-dark" to="reset_code">Reset Code</label>
                        <input v-model="token" name="reset_code" placeholder="Enter the OTP Code sent to your email"
                          class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                          required />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.token" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="text-dark" to="password">Password</label>
                        <input v-model="password" name="password" type="password" required
                          class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(error, index) in formErrors.password" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="text-dark" to="password_confirmation">Confim Password</label>
                        <input v-model="password_confirmation" name="password_confirmation" required type="password"
                          class="py-2 px-3 border rounded w-full hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2" />
                        <div v-if="formErrors">
                          <vs-alert class="mb-1" v-for="(
                              error, index
                            ) in formErrors.password_confirmation" :key="index">
                            {{ error }}
                          </vs-alert>
                        </div>
                      </div>

                      <div class="form-group text-center mt-5">
                        <button :disabled="loading" type="submit"
                          class="bg-primary text-white py-3 px-4 mr-3 text-sm hover:bg-primary rounded hover:text-white focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 w-full">
                          Reset Password
                          <BtnLoading v-if="loading" class="d-inline-block" height="18" />
                        </button>
                      </div>
                      <p>
                        Didn't receive the code?
                        <a href="#" @click="resendOTP">Resend OTP Code</a>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnLoading from "@/components/BtnLoading.vue";

export default {
  components: { BtnLoading },
  data() {
    return {
      email: "",
      token: "",
      password: "",
      loading: false,
      formErrors: {
        email: "",
        password: "",
      },
      emailSent: false,
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
    };
  },
  mounted() { },
  methods: {
    submitForm() {
      this.loading = true;
      let data = {
        email: this.email,
      };
      let payload = {
        data,
        path: "/auth/password/forgot",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.loading = false;

          this.$toast.success(
            "Password Reset",
            "Password reset code has been sent to your email.",
            this.$toastPosition
          );
          this.emailSent = true;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status == 404) {
              this.$toast.info(
                "Password Reset",
                "Email address not found. Check and try again",
                this.$toastPosition
              );
              return;
            }
            this.formErrors = err.response.data.data.errors;
            setTimeout(() => {
              this.formErrors = {};
            }, 3000);
            this.$toast.info(
              "Password Reset",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Password Reset",
              "Unable to send email, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },

    submitFormReset() {
      this.loading = true;
      let data = {
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      let payload = {
        data,
        path: "/auth/password/reset",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.loading = false;

          this.$toast.success(
            "Password Reset",
            "Password has been reset successfully.",
            this.$toastPosition
          );
          this.$router.push("/login");
          this.emailSent = true;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            if (err.response.status == 404) {
              this.$toast.info(
                "Password Reset",
                "Email address not found. Check and try again",
                this.$toastPosition
              );
              return;
            }
            this.formErrors = err.response.data.data.errors;
            setTimeout(() => {
              this.formErrors = {};
            }, 3000);
            this.$toast.info(
              "Password Reset",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Password Reset",
              "Unable to send email, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },

    resendOTP() {
      this.$vs.loading();
      let data = {
        email: this.email,
      };
      let payload = {
        data,
        path: "/auth/account/resend-otp",
      };
      this.$store
        .dispatch("authRequest", payload)
        .then((resp) => {
          this.$vs.loading.close();

          this.$toast.success(
            "Password Reset",
            "Password reset code has been sent to your email.",
            this.$toastPosition
          );
          this.emailSent = true;
        })
        .catch((err) => {
          this.$vs.loading.close();
          if (err.response) {
            if (err.response.status == 404) {
              this.$toast.info(
                "Password Reset",
                "Email address not found. Check and try again",
                this.$toastPosition
              );
              return;
            }
            this.formErrors = err.response.data.data.errors;
            setTimeout(() => {
              this.formErrors = {};
            }, 3000);
            this.$toast.info(
              "Password Reset",
              err.response.data.message,
              this.$toastPosition
            );
          } else {
            this.$toast.info(
              "Password Reset",
              "Unable to send email, check the details and try again",
              this.$toastPosition
            );
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-site-white {
  background-color: white !important;
  color: black;
  box-shadow: 0px 0px;
  border: 1px solid black;
}
</style>
